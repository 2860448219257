:root {
    --primary-color: #757575;
    --secondary-color: #F2BA40;
    --background-color: #F2F2F2;
    --highlight-color: #F2BA40;
    --box-color: #EEEEEE;
    --font-color: #333333;
    --font-size-big: 17px;
    --font-size-small: 12px;
}

.footer {
    padding-top: 32px;
    font-size: 12px;
}

/* --- Overrite bootstrap ---*/
.form-group {
    padding: 0 10px;
}

.form-control {
    color: var(--font-color);
    background-color: var(--box-color);
    border-color: var(--secondary-color);
    margin-bottom: 15px;
}

.form-control:focus {
    border-color: var(--highlight-color);
    box-shadow: none;
}

.progress {
    margin-left: 10px;
}

.progress-bar {
    background-color: var(--secondary-color);
}

.button {
    background-color: var(--secondary-color);
    border: 1px solid var(--highlight-color);
    font-size: 13px;
    font-weight: 600;
    color: white;
    height: 37px;
}

/* >>> GENERAL <<<*/
body {
    background: var(--background-color);
    font-family: sans-serif;
    color: var(--font-color);
    padding: 15px;
    height: 100%;
    width: 100%;
}

a {
    color: var(--primary-color);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--font-color);
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--font-color);
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--font-color);
}


.loader {
    text-align: center;
    color: var(--primary-color);
}

.description {
    font-size: var(--font-size-small);
    font-weight: 500;
    font-family: sans-serif;
}

.title {
    font-size: var(--font-size-big);
    font-weight: bold;
}

.flexCenter {
    display: flex;
    justify-content: center;
}

.pointer {
    cursor: pointer;
}

/* >>> COMPONENTS <<<*/

.progressBarContainer {
    display: flex;
    align-items: center;
    max-width: 400px;
    min-height: 32px;
    width: 100%;
}

.paginationContainer {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
    height: 32px;
}

.paginationNumbering {
    font-weight: bold;
    color: var(--font-color);
}

.paginationNumbering .highlighted {
    color: var(--secondary-color);
}

.pagination {
    display: inline-block;
    font-size: 10px;
    text-align: center;
    border: 1px solid var(--primary-color);
    border-radius: 20px;
    height: 18px;
    width: 18px;
    cursor: pointer;
    margin-left: 6px;
}

/*Question*/
.paper-item-wrapper {
    margin-top: 10px;
}

.paper {
    max-width: 180px;
    max-height: 180px;
    padding: 20px;
    cursor: pointer;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    border: 1px solid var(--primary-color) !important;
    background-color: var(--box-color);
    color: var(--primary-color);
}

.paper > svg {
    width: 100%;
    max-width: 136px;
    height: 100%;
    max-height: 136px;
    margin-bottom: 20px;
}

.paper > svg * {
    fill: var(--primary-color);
}

.paper:hover {
    border-color: var(--secondary-color) !important;
    box-shadow: rgb(170, 170, 170) 2px 2px 4px 0;
}

.paper:hover > svg * {
    fill: var(--secondary-color);
}

.paper:hover .description {
    color: var(--secondary-color);
}

.questionSelected > svg * {
    fill: var(--secondary-color);
}

.questionSelected {
    color: var(--secondary-color);
    border-color: var(--secondary-color) !important;
}

/*QuestionsPage*/
.page {
    align-items: center;
    display: flex;
    flex-direction: column;
    transition: all 1s ease;
}

.pageHeader {
    margin-bottom: 25px;
}

.infoBox {
    position: relative;
    cursor: pointer;
    margin-left: 6px;
    color: var(--font-color);
}

.popup {
    display: none;
    position: absolute;
    top: 24px;
    right: 0px;
    z-index: 5;
    width: 280px;
    padding: 4px;
    background-color: #ffffff;
    border: 1px solid var(--primary-color);
    border-radius: 5px 0px 5px 5px;
    box-shadow: rgb(170, 170, 170) 4px 4px 4px 0px;
}

.infoBox:hover > .popup {
    display: block;
}

/*Summary table*/
.summaryTable {
    position: relative;
    text-align: center;
    background: var(--box-color);
    padding: 10px 20px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    /* Styles */
    .summaryTable {
        position: relative;
        text-align: center;
        background: var(--box-color);
        padding: 10px;
    }

    body {
        padding: 0px;
    }

    .container  {
        margin-left: 0px !important;
        margin-right: 0px;
        padding: 0px;
    }

    .paginationContainer {
        padding: 15px
    }

    .footer {
        padding: 15px
    }

    .padding {
        padding: 15px
    }

}

.summaryTableContent {
    display: flex;
    flex-wrap: wrap;
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
}

.summaryTableRow {
    display: flex;
    justify-content: space-between;
    text-align: start;
}

.summaryTableSum {
    text-align: right;
    font-size: 20px;
}

/*Custom Form*/
.contact {
    display: flex;
    flex-flow: column;
    flex: 1;
    align-items: center;
    padding: 10px;
}

.content {
    min-width: 364px;
    display: flex;
}

.content .header {
    font-size: 1rem;
    font-weight: bold;
}

.content .description {
    padding-top: 6px;
    padding-bottom: 12px;
    font-size: 1rem;
}

.content .avatar {
    width: 80px;
    height: 80px;
    border-radius: 120px;
    margin-right: 20px;
}

.content .line {
    flex: 1;
    border: 1px solid var(--secondary-color);
}

/*CHECKBOX*/
.b-contain *,
.b-contain *::before,
.b-contain *::after {
    box-sizing: content-box !important;
}

.b-contain input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.b-contain span {
    line-height: 1.54;
    font-size: 1rem;
    font-family: inherit;
}

.b-contain {
    display: table;
    position: relative;
    padding-left: 1.8rem;
    cursor: pointer;
    margin-bottom: 0.5rem;
}

.b-contain input[type='checkbox'] ~ .b-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    background: var(--box-color);
    transition: background 250ms;
    border: 1px solid var(--secondary-color);
    border-radius: 1.241rem;
}

.b-contain input[type='radio'] ~ .b-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    background: var(--box-color);
    transition: background 250ms;
    border: 1px solid var(--secondary-color);
    border-radius: 2rem;
}

.b-contain input[type='checkbox'] ~ .b-input::after {
    content: '';
    position: absolute;
    display: none;
    left: 0.45rem;
    top: 0.18rem;
    width: 0.25rem;
    height: 0.6rem;
    border: solid var(--secondary-color);
    border-width: 0 2px 2px 0;
    transition: background 250ms;
    transform: rotate(45deg);
}

.b-contain input[type='radio'] ~ .b-input::after {
    content: '';
    position: absolute;
    display: none;
    left: 0.25rem;
    top: 0.25rem;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 2rem;
    background: var(--secondary-color);
    transition: background 250ms;
}

.b-contain input:disabled ~ .b-input::after {
    border-color: rgba(135, 149, 161, 1);
}

.b-contain input:checked ~ .b-input::after {
    display: block;
}

.b-contain:hover input ~ .b-input,
.b-contain input:focus ~ .b-input {
    background: var(--secondary-color);
}

.b-contain input:focus ~ .b-input {
    box-shadow: 0 0 0 0px rgba(231, 238, 243, 1);
}

.b-contain input:checked ~ .b-input {
    background: rgba(224, 224, 224, 1);
    border-color: rgba(231, 238, 243, 1);
}

.b-contain input[type='checkbox']:disabled ~ .b-input {
    background: rgba(241, 245, 248, 1);
    border-color: rgba(184, 194, 204, 1);
    opacity: 0.6;
    cursor: not-allowed;
}

.b-contain input[type='radio']:disabled ~ .b-input {
    background: rgba(241, 245, 248, 1);
    border-color: rgba(184, 194, 204, 1);
    opacity: 0.6;
    cursor: not-allowed;
}

.b-contain input[type='radio']:disabled ~ .b-input::after {
    background: rgba(135, 149, 161, 1);
}

.b-contain input:checked:focus ~ .b-input,
.b-contain:hover input:not([disabled]):checked ~ .b-input {
    background: rgba(224, 224, 224, 1);
    border-color: rgba(231, 238, 243, 1);
}

.b-contain .b-input::before {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 3rem;
    height: 3rem;
    margin-left: -0.85rem;
    margin-top: -0.85rem;
    background: var(--box-color);
    border-radius: 2rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
}

@keyframes b-ripple {
    0% {
        transform: scale(0);
    }

    20% {
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

@keyframes b-ripple-duplicate {
    0% {
        transform: scale(0);
    }

    30% {
        transform: scale(1);
    }

    60% {
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}

.b-contain input + .b-input::before {
    animation: b-ripple 250ms ease-out;
}

.b-contain input:checked + .b-input::before {
    animation-name: b-ripple-duplicate;
}

.b-contain .b-input::before {
    visibility: hidden;
}

.b-contain input:focus + .b-input::before {
    visibility: visible;
}

.b-contain:first-child .b-input::before {
    visibility: hidden;
}
